import 'regenerator-runtime/runtime'
import * as THREE from "three";
// import { GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
// import { TimelineMax } from "gsap";
// import model from "../model/Sky.glb"
// import model2 from "../model/Sky.glb"
// import model3 from "../model/Sky.glb"
// import mountain from "/images/featured.png";


import fragment from "./shaders/fragmentbg.glsl";
import vertex from "./shaders/vertexbg.glsl";
let OrbitControls = require("three-orbit-controls")(THREE);


// alert(globalVariable.aa);

export default class SketchBack {
  constructor(options) {
    this.scene = new THREE.Scene();

    this.container = options.dom;
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.renderer = new THREE.WebGLRenderer();
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.width, this.height);
    this.renderer.setClearColor(0x000000, 0); 
    this.renderer.physicallyCorrectLights = true;
    this.renderer.outputEncoding = THREE.sRGBEncoding;

    this.container.appendChild(this.renderer.domElement);

    this.camera = new THREE.PerspectiveCamera(
      70,
      window.innerWidth / window.innerHeight,
      0.001,
      1000
    );
    var PI = 3.141592653589793238;
    // var frustumSize = 10;
    // var aspect = window.innerWidth / window.innerHeight;
    // this.camera = new THREE.OrthographicCamera( frustumSize * aspect / - 2, frustumSize * aspect / 2, frustumSize / 2, frustumSize / - 2, -1000, 1000 );
    this.camera.position.set(0, 0, 2);
    // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.time = 0;
    this.sc = 0;
    this.aaaa = 0;

    this.isPlaying = true;
    
    this.addObjects();
    this.resize();
    this.render();
    this.setupResize();
    // this.settings();

    // this.loader = new GLTFLoader();
    //     this.loader.load(model, (gltf)=>{
    //         console.log(gltf);
    //         this.face1 = gltf.scene;
    //         this.pivot1 = new THREE.Group();
          
    //     this.pivot1.add( this.face1 );
    //     // this.pivot1.position.x =1;
    //     this.scene.add( this.pivot1 );
    //         // this.scene.add(this.face1);
    //         gltf.scene.traverse(o=>{
    //             if(o.isMesh){
    //                 o.geometry.center();
    //                 o.scale.set(0.7,0.7,0.7);
    //                 o.material = this.material;
    //                 o.rotateX(-PI/2.1);
                    
                    
    //             }
    //         })
            
    //     })

        

    //     this.loader.load(model2, (gltf)=>{
    //       console.log(gltf);
    //       this.face2 = gltf.scene;
    //       this.pivot2 = new THREE.Group();
          
    //       this.pivot2.add( this.face2 );
    //       this.pivot2.position.x =5;
    //       this.scene.add( this.pivot2 );
          
    //       // this.scene.add(this.face2);
    //       gltf.scene.traverse(o=>{
    //           if(o.isMesh){
    //             // o.translate = (1200, 0, 0);
    //               // o.geometry.center();
    //               o.scale.set(0.7,0.7,0.7);
    //               o.material = this.material;
    //               o.rotateX(-PI/2.1);
    //               // o.translate = (6,0,0);
    //               // o.position.x = 6;
                  
                  
                  
    //           }
    //       })
          

          
    //       this.loader.load(model3, (gltf)=>{
    //         console.log(gltf);
    //         this.face3 = gltf.scene;
    //         this.pivot3 = new THREE.Group();
          
    //     this.pivot3.add( this.face3 );
    //     this.pivot3.position.x =10;
    //     this.scene.add( this.pivot3 ); 
    //         // this.scene.add(this.face3);
    //         gltf.scene.traverse(o=>{
    //             if(o.isMesh){
    //                 o.geometry.center();
    //                 o.scale.set(0.7,0.7,0.7);
    //                 o.material = this.material;
    //                 o.rotateX(-PI/2.1);
    //                 // o.translate = (12,0,0);
                    
                    
    //             }
    //         })
            
    //     })

                  
          
          
      // })

            
  }

  settings() {
    let that = this;
    this.settings = {
      progress: 0,
    };
    this.gui = new dat.GUI();
    this.gui.add(this.settings, "progress", 0, 1, 0.01);
  }

  setupResize() {
    window.addEventListener("resize", this.resize.bind(this));
  }

  resize() {
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.renderer.setSize(this.width, this.height);
    this.camera.aspect = this.width / this.height;
    this.camera.updateProjectionMatrix();
  }

  addObjects() {
    let that = this;
    this.material = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable"
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { type: "f", value: 0 },
        sc: { type: "f", value: 0 },
        ba: { type: "f", value: 0 },
        // mountain: {type: "t", value: new THREE.TextureLoader().load(mountain)},
        resolution: { type: "v4", value: new THREE.Vector4() },
        uvRate1: {
          value: new THREE.Vector2(1, 1)
        }
      },
      // wireframe: true,
      // transparent: true,
      vertexShader: vertex,
      fragmentShader: fragment
    });

    this.geometry = new THREE.SphereBufferGeometry(4.5, 100, 100);
    // this.geometry = new THREE.BoxBufferGeometry(30.5, 100, 100);


    this.plane = new THREE.Mesh(this.geometry, this.material);
    this.plane.position.z = -10;
    this.plane.scale.set(3.5, 3.5, 3.5);
    this.scene.add(this.plane);

  }

  stop() {
    this.isPlaying = false;
  }

  play() {
    if(!this.isPlaying){
      
      this.render()
      this.isPlaying = true;
    }
  }

  render() {
    if (!this.isPlaying) return;
    this.time += 0.05;
    // if (this.face1) this.face1.rotation.y += 0.01;
    if (this.face1) this.face1.rotateY(0.01);
    if (this.face2) this.face2.rotateY(0.01);
    if (this.face3) this.face3.rotateY(0.01);
    // if (this.face3) this.face3.rotation.y += 0.01;
    this.material.uniforms.time.value = this.time;
    this.material.uniforms.sc.value = this.sc;
    // this.material.uniforms.ba.value = sketch.aa;
    console.log(this.aaaa);
    requestAnimationFrame(this.render.bind(this));
    
    
    this.renderer.render(this.scene, this.camera);
    
  }
}

// new Sketch({
//   dom: document.getElementById("container")
// });

